/* @charset "utf-8" */
/* CSS Document */

// IMPORT - START

@import form-initialization-general

// IMPORT - END


// GENERAL - START

/* FORM - START */

form
	margin-bottom: $SPACE_HUGE
	margin-top: $SPACE_MEDIUM
form.FormInsertConversation
	div.DivConversationGeneral
		div.DivConversationContent
			textarea
				border-bottom-left-radius: 0px
				border-bottom-right-radius: 0px
				resize: none
				margin-bottom: 0px
				padding-bottom: 0px

div.DivForm, form div.DivForm fieldset, div.DivSiteContainer
	input, textarea
		+setBoxSizing(border-box)
	input
		height: $HEIGHT_INPUT_SINGLELINE
	input, select, textarea, textarea.TextareaDisable, .ng-select-container
		margin-bottom: $SPACE_NARROW
		margin-top: $SPACE_NARROW
		width: 100%
		min-width: $WIDTH_INPUT_SQUARE
		padding-top: $PADDING_INPUT_EDGE
		padding-bottom: $PADDING_INPUT_EDGE
		padding-right: $SPACE_MEDIUM
		padding-left: $SPACE_MEDIUM
		font-size: $FONT_LABEL_SUBTITLE
		display: block
	input, select
		+setBorderRadius($RADIUS_INPUT_GENERAL)
	textarea, textarea.TextareaDisable, .ng-select-container
		+setBorderRadius($SPACE_TINY)
	input.InputToken
		min-width: $WIDTH_INPUT_SQUARE
		width: $WIDTH_INPUT_SQUARE
		text-align: center
	input::-webkit-outer-spin-button, input::-webkit-inner-spin-button
		+setAppearance(none)
		margin: 0px
	input[type=number]
		+setAppearance(textfield)
	input.InputNoMargin
		margin-top: 0px
		margin-bottom: 0px
	mat-slider
		width: 100%
	select
		min-height: $HEIGHT_SELECT_SINGLELINE
		background-repeat: no-repeat
		background-position: right
		+setAppearance(none)
	textarea
		height: $HEIGHT_TEXTAREA_GENERAL
	textarea.TextareaDescriptionHeader
		height: $HEIGHT_TEXTAREA_HEADER !important
		font-size: $FONT_INPUT_TEXTAREA_HEADERHEADLINE
		margin: 0px
	textarea.TextareaDisable
		height: $HEIGHT_TEXTAREA_READONLY
		resize: none
		padding: 0
		+setBorderRadius(0px)
	div.DivRadioButtonContainerVertical
		flex-direction: column
	div.DivRadioButtonContainer, div.DivRadioButtonContainerVertical, div.DivCheckBoxContainer
		label.LabelRadioButtonContainer, label.LabelCheckboxContainer
			line-height: 1.1
			display: flex !important
			align-items: center
			flex-direction: row
			gap: 10px
			input[type=radio], input[type=checkbox]
				border: 0.25em solid #787878
				-webkit-appearance: none
				appearance: none
				background-color: transparent
				margin: 0 0 5px 0
				font: inherit
				color: black
				transform: translateY(-0.075em)
				display: grid
				place-content: center
				padding: 5px 10px !important
				&::before
					content: ""
					transform: scale(0)
					transition: 100ms transform ease-in-out
					background-color: transparent
				&:checked
					&::before
						transform: scale(1)
			&:last-of-type
				margin: 0
		label.LabelRadioButtonContainer
			margin-left: 10px !important
			input[type=radio]
				width: 35px !important
				height: 35px !important
				border-radius: 50%
				&::before
					width: 22px
					height: 22px
					border-radius: 50%
					box-shadow: inset 23px 23px #00529C
		label.LabelCheckboxContainer
			input[type=checkbox]
				width: 30px !important
				height: 30px !important
				min-height: 0px
				min-width: 0px
				border-radius: 8px
				&::before
					content: ""
					width: 15px
					height: 15px
					border-radius: 4px
					box-shadow: inset 15px 15px #00529C
	div.DivRadioButtonContainer, div.DivRadioButtonContainerVertical
		display: flex
		margin-bottom: 20px
		margin-top: 0px
		min-height: 50px
		label
			width: 165px !important
		div.DivRadioButton
			margin: auto $SPACE_LITTLE
			width: $SIZE_INPUT_RADIO
			height: $SIZE_INPUT_RADIO
			padding: $SPACE_TINY
			border-style: solid
			border-width: $WIDTH_BORDER_INPUTTHICK
			+setBorderRadius(50%)
			display: flex
			align-items: center
			align-content: center
			justify-content: center
			input[type=radio]
				display: none
			label
				margin: 0px
				width: 0px
				height: 0px
				+setBorderRadius(50%)
			input[type=radio]:checked + label
				width: 100%
				height: 100%
		div.DivRadioButton + label
			margin-right: $SPACE_MEDIUM
		div.DivRadioButtonMerge
			display: flex
			align-items: center
		div.DivRadioButtonMultiple
			display: flex
	div.DivMultipleRadioButtonContainer
		div.DivRadioButtonProduct
			display: flex
	select.SelectMobilePhonePrefix
		// max-width: $WIDTH_INPUT_MOBILEPHONE_PREFIX
		// width: $WIDTH_INPUT_MOBILEPHONE_PREFIX
		text-align: left
		margin-right: $SPACE_LITTLE
		max-width: 70px !important
		background-image: none
	select.InputLicensePlatePrefix
		min-width: $WIDTH_INPUT_LICENSEPLATE_PREFIX
		width: $WIDTH_INPUT_LICENSEPLATE_PREFIX
		text-align: left
		margin-right: $SPACE_TINY
	input.InputLicensePlateInfix
		min-width: $WIDTH_INPUT_LICENSEPLATE_INFIX
		// width: $WIDTH_INPUT_LICENSEPLATE_INFIX
		max-width: 500px
		text-align: center
		margin-right: $SPACE_TINY
		margin-left: $SPACE_TINY
	input.InputLicensePlateSuffix
		min-width: $WIDTH_INPUT_SQUARE
		width: $WIDTH_INPUT_LICENSEPLATE_SUFFIX
		max-width: $WIDTH_INPUT_MOBILEPHONE_PREFIX * 1.5
		text-align: center
		margin-left: $SPACE_TINY
	fieldset
		mat-form-field
			width: 100%
	div.DivCheckBoxContainer
		display: flex
		align-items: center
		padding-left: 20px
		padding-bottom: 20px
		div.DivCheckBox
			margin-right: $SPACE_LITTLE
			width: $SIZE_INPUT_CHECKBOX
			height: $SIZE_INPUT_CHECKBOX
			padding: $PADDING_INPUT_CHECKBOX
			border-style: solid
			border-width: $WIDTH_BORDER_INPUTTHICK
			+setBorderRadius($RADIUS_INPUT_CHECKBOXOUTER)
			display: flex
			align-items: center
			align-content: center
			justify-content: center
			input[type=checkbox]
				display: none
			label
				margin: 0px
				width: 0px
				height: 0px
				+setBorderRadius($RADIUS_INPUT_CHECKBOXINNER)
			input[type=checkbox]:checked + label
				width: 100%
				height: 100%
		div.DivCheckBox + label
			margin-right: $SPACE_MEDIUM
			margin-top: $SPACE_NARROW
			margin-bottom: $SPACE_NARROW
	mat-mdc-form-field
		margin: 0px
		padding: 0px
		min-width: auto
	div.DivTimer
		display: flex
		justify-content: flex-start
		select.SelectTimePrefix
			width: $SIZE_LAYOUT_PROFILEIMAGE
		span.SpanTimeInfix
			margin-top: $SPACE_SMALL
			margin-left: $SPACE_LITTLE
			margin-right: $SPACE_LITTLE
		select.SelectTimeSuffix
			width: $SIZE_LAYOUT_PROFILEIMAGE
	input.mat-input-element
			margin: 0px
			padding: 0px
			min-width: auto
	.ng-dropdown-panel
		border: 1px solid #999090
	div.DivInputHorizontalContainer
		label.LabelStringPrefixForm
			margin-top: $SPACE_MEDIUM !important
			margin-right: $SPACE_LITTLE !important
	p.ParagraphNoteInput
		margin-left: 35px !important
div.DivMainAsideSearch, div.DivTransparantContainer
	div.DivDynamicContainer
		form
			width: auto
		input[type=button].ButtonSubmit
			width: $WIDTH_LINK_BUTTON_CONVERSATION
div.DivMainAsideSearch
	form
		margin-right: $SPACE_SMALL
		width: $WIDTH_LAYOUT_SIGNIN
		flex-shrink: 0

/* FORM - END */

/* THIRD PARTY - START */

div.DivForm, form div.DivForm fieldset, div.DivContentBox
	.mat-mdc-form-field-underline
		display: none
	input.mat-date-range-input-inner, input.mat-datepicker-input
		height: auto
		font: inherit
		border: none
		outline: none
		padding: 0
		margin: 0
		vertical-align: bottom
		text-align: inherit
		+setAppearance(none)
		width: 100%
	.mat-mdc-form-field-infix
		padding: 15px
		height: 45px
	.mat-mdc-form-field-icon-suffix
		margin: 0px 5px 0px 0px
		.mat-datepicker-toggle-default-icon
			width: 21px !important
			height: 21px !important

div#divMainDashboard
	.mat-mdc-form-field
		margin-bottom: $SPACE_NARROW
		margin-top: $SPACE_NARROW
		div.mat-mdc-form-field-infix
			input
				margin: 0px
				height: 20px
	.mat-mdc-form-field.matFormFieldPolicyholderExpiredDrivingLicense
		margin-bottom: 0px
		margin-top: 0px
	.mat-mdc-form-field-subscript-wrapper
		height: 0

div.DivForm
	fieldset
		width: 100%
		padding: $SPACE_LITTLE
		margin-bottom: $SPACE_LITTLE
		margin-top: $SPACE_LITTLE
		border: none
		border-radius: $SPACE_LITTLE
	.ng-select
		margin-right: $SPACE_LITTLE
		width: 100%
	.ng-dropdown-panel
		width: 100%
		padding-left: $SPACE_LITTLE
	.ng-option
		margin-top: $SPACE_TINY
	.ng-select.custom .ng-clear-wrapper .ng-clear
		font-size: 0px

agm-map
	margin: 200px
	background-color: red
	height: 500px
	width: 100%

/* THIRD PARTY - END */

// GENERAL - END


// NAVIGATION - START

// NAVIGATION - END


// PREMIUM CALCULATOR - START

div#divPremiumCalcContainer
section.SectionFormPremiumCalcContainer, div.SectionFormPremiumCalcContainer
	form
		div.DivButtonContainer
			input[type=button]
				height: $HEIGHT_LAYOUT_LOGOHEADER
				margin-bottom: $SPACE_MEDIUM
				margin-top: $SPACE_LARGE

// PREMIUM CALCULATOR - END


// SIGN IN - START

div#divSignInContainer
	section.SectionFormSignInContainer, div.SectionFormSignInContainer
		form
			div.DivButtonContainer
				input[type=button]
					height: $HEIGHT_LAYOUT_LOGOHEADER
					margin-bottom: $SPACE_MEDIUM
					margin-top: $SPACE_LARGE

// SIGN IN - END


// SIGN UP - START

div#divSignUpContainer
	section.SectionFormSignUpContainer
		form
			div.DivButtonContainer
				input[type=button]
					height: $HEIGHT_LAYOUT_LOGOHEADER
					margin-bottom: $SPACE_MEDIUM
					margin-top: $SPACE_LARGE

// SIGN UP - END


// RENEWAL - START

div.DivTableListContainerLeft
	div.DivTableListControlLeft
		select
			padding-top: $PADDING_INPUT_EDGE
			padding-bottom: $PADDING_INPUT_EDGE
			padding-right: $PADDING_INPUT_SIDE
			padding-left: $PADDING_INPUT_SIDE
			border-style: none
			background-repeat: no-repeat
			background-position: right
			+setAppearance(none)
	div.DivTableListControlLeft
		select
			margin-bottom: $SPACE_NARROW
			margin-top: $SPACE_NARROW
			width: 100%
			min-width: $WIDTH_INPUT_SINGLELINE
			min-height: $HEIGHT_SELECT_SINGLELINE
			font-size: $FONT_INPUT_GENERAL
			+setBorderRadius($RADIUS_INPUT_GENERAL)

// RENEWAL - END

// TABLE - START

div.DivTableContainer
	div.DivTableControl
		select
			padding-top: $PADDING_INPUT_EDGE
			padding-bottom: $PADDING_INPUT_EDGE
			padding-right: $PADDING_INPUT_SIDE
			margin-left: $MARGIN_INPUT_SIDE
			margin-right: $MARGIN_INPUT_SIDE
			border-style: none
			background-repeat: no-repeat
			background-position: right
			+setBorderRadius($RADIUS_INPUT_GENERAL)
	div.DivTableControl
		select
			margin-bottom: $SPACE_NARROW
			margin-top: $SPACE_NARROW
			width: 100%
			padding: $MARGIN_INPUT_SIDE
			min-width: $WIDTH_INPUT_SINGLELINE
			min-height: $HEIGHT_SELECT_SINGLELINE
			padding-right: inherit
			font-size: $FONT_INPUT_GENERAL
			border-style: none
			background-repeat: no-repeat
			+setBorderRadius($MARGIN_INPUT_SIDE)
		input
			font-size: $FONT_INPUT_GENERAL
	div.DivConversationStatus
		select
			font-size: $FONT_SELECT_SEVERITY
			width: $WIDTH_LABEL_CONVERSATIONSTATUS
			+setBorderRadius($RADIUS_TICKET_STATUS)
	div.DivConversationContent
		textarea
			+setBoxSizing(border-box)
			width: 100%
			min-width: $WIDTH_INPUT_SQUARE
			padding: $PADDING_INPUT_SIDE
			font-size: $FONT_INPUT_GENERAL
			border-style: none
			+setBorderRadius($RADIUS_INPUT_GENERAL)

// TABLE - END

// BRINS-WEBSITE - START

div#divMainDashboard
	form
		div.DivForm
			fieldset
				input, inputarea, select, ng-select
					height: 68px
				ng-select
					margin-bottom: 0px
				textarea
					height: 220px
				input.inputAttachment
					width: 300px
					+setBorderRadius(10px)
			input.inputButton
				display: block
				width: 300px
				margin: 20px auto
				+setBorderRadius(30px)
	input, input::-webkit-input-placeholder
		font-size: 18px

div#divBodyFooterContainer
	div.DivFooterInfoPage
		input
			width: 100%
			height: $SPACE_BIG
			border-radius: ($SPACE_SMALL + $SPACE_MEDIUM)
			padding: $SPACE_LITTLE $SPACE_HUGE
			font-size: 1.1em
			border-style: none
		button
			position: absolute
			right: ($PADDING_INPUT_CHECKBOX + $SPACE_THIN)
			top: $SPACE_TINY
			bottom: $SPACE_TINY
			border: 0
			color: #fff
			outline: none
			width: ($SIZE_LINK_BUTTONATTACHMENT * 3)
			height: ($SIZE_LINK_BUTTONATTACHMENT * 3)
			padding: 0 $SPACE_LITTLE
			margin-left: -$SPACE_BIG
			border-radius: $SIZE_TABLE_THUMBNAILSICONSMALL
			z-index: 2

// BRINS-WEBSITE - END