/* @charset "utf-8"; */
/* CSS Document */

// IMPORT - START

@import form-initialization-general

// IMPORT - END


// TYPEFACE - START

/* TERTIARY - START */

@font-face
	font-family: $URL_FAMILYTERTIARY_PRIMARY
	src: url($URL_FONT_RESOURCE + $URL_FONT_TERTIARY + $URL_FAMILYTERTIARY_PRIMARY + '.eot?#iefix') format('embedded-opentype'), url($URL_FONT_RESOURCE + $URL_FONT_TERTIARY + $URL_FAMILYTERTIARY_PRIMARY + '.woff2') format('woff2'), url($URL_FONT_RESOURCE + $URL_FONT_TERTIARY + $URL_FAMILYTERTIARY_PRIMARY + '.woff') format('woff'), url($URL_FONT_RESOURCE + $URL_FONT_TERTIARY + $URL_FAMILYTERTIARY_PRIMARY + '.ttf') format('truetype'), url($URL_FONT_RESOURCE + $URL_FONT_TERTIARY + $URL_FAMILYTERTIARY_PRIMARY + '.svg#' + $URL_FAMILYTERTIARY_PRIMARY) format('svg')
	font-weight: normal
	font-style: normal

@font-face
	font-family: $URL_FAMILYTERTIARY_SECONDARY
	src: url($URL_FONT_RESOURCE + $URL_FONT_TERTIARY + $URL_FAMILYTERTIARY_SECONDARY + '.eot?#iefix') format('embedded-opentype'), url($URL_FONT_RESOURCE + $URL_FONT_TERTIARY + $URL_FAMILYTERTIARY_SECONDARY + '.woff2') format('woff2'), url($URL_FONT_RESOURCE + $URL_FONT_TERTIARY + $URL_FAMILYTERTIARY_SECONDARY + '.woff') format('woff'), url($URL_FONT_RESOURCE + $URL_FONT_TERTIARY + $URL_FAMILYTERTIARY_SECONDARY + '.ttf') format('truetype'), url($URL_FONT_RESOURCE + $URL_FONT_TERTIARY + $URL_FAMILYTERTIARY_SECONDARY + '.svg#' + $URL_FAMILYTERTIARY_SECONDARY) format('svg')
	font-weight: normal
	font-style: normal

@font-face
	font-family: $URL_FAMILYTERTIARY_TERTIARY
	src: url($URL_FONT_RESOURCE + $URL_FONT_TERTIARY + $URL_FAMILYTERTIARY_TERTIARY + '.eot?#iefix') format('embedded-opentype'), url($URL_FONT_RESOURCE + $URL_FONT_TERTIARY + $URL_FAMILYTERTIARY_TERTIARY + '.woff2') format('woff2'), url($URL_FONT_RESOURCE + $URL_FONT_TERTIARY + $URL_FAMILYTERTIARY_TERTIARY + '.woff') format('woff'), url($URL_FONT_RESOURCE + $URL_FONT_TERTIARY + $URL_FAMILYTERTIARY_TERTIARY + '.ttf') format('truetype'), url($URL_FONT_RESOURCE + $URL_FONT_TERTIARY + $URL_FAMILYTERTIARY_TERTIARY + '.svg#' + $URL_FAMILYTERTIARY_TERTIARY) format('svg')
	font-weight: normal
	font-style: normal

@font-face
	font-family: $URL_FAMILYTERTIARY_QUARTENERY
	src: url($URL_FONT_RESOURCE + $URL_FONT_TERTIARY + $URL_FAMILYTERTIARY_QUARTENERY + '.eot?#iefix') format('embedded-opentype'), url($URL_FONT_RESOURCE + $URL_FONT_TERTIARY + $URL_FAMILYTERTIARY_QUARTENERY + '.woff2') format('woff2'), url($URL_FONT_RESOURCE + $URL_FONT_TERTIARY + $URL_FAMILYTERTIARY_QUARTENERY + '.woff') format('woff'), url($URL_FONT_RESOURCE + $URL_FONT_TERTIARY + $URL_FAMILYTERTIARY_QUARTENERY + '.ttf') format('truetype'), url($URL_FONT_RESOURCE + $URL_FONT_TERTIARY + $URL_FAMILYTERTIARY_QUARTENERY + '.svg#' + $URL_FAMILYTERTIARY_QUARTENERY) format('svg')
	font-weight: normal
	font-style: normal

/* TERTIARY - END */

// TYPEFACE - END


// SETUP - START

/* NEUTRALIZE - START */

body
	font-family: $URL_FAMILYTERTIARY_PRIMARY

h1, h2, h3, h4, h5, h6, p, mark, ol, ul, li
	margin: none auto
	padding: auto
	font-family: inherit
	font-size: inherit
	font-weight: inherit
	color: inherit
	text-decoration: inherit
	text-transform: inherit

ol, ul
	padding-left: 0px
	padding-right: 0px
	list-style-position: inside
	li
		padding-left: 0px
		padding-right: 0px
		list-style-position: inside

/* NEUTRALIZE - END */

/* SEMANTIC ELEMENTS - START */

img
	border: none
	object-fit: cover
	display: block
	margin: auto

label, input[type=button], input[type=submit], input[type=reset], a, img#imageLogoCart
	cursor: pointer
	cursor: hand

*
	+setTransition(all, 0.25, ease-out)
	// "position, margin, width, height, padding, background-color, opacity, border"
	scrollbar-width: none
	-ms-overflow-style: none
	::-webkit-scrollbar
		width: 0
		height: 0
	::-webkit-input-placeholder, :-moz-placeholder, ::-moz-placeholder, :-ms-input-placeholder, ::placeholder
		text-transform: none

body
	position: relative
	width: 100%
	height: 100vh

form, body, footer
	padding: 0px
	margin: 0px

div.DivBackground
	position: fixed
	top: 0px
	left: 0px
	right: 0px
	bottom: 0px
	z-index: -2

footer
	position: fixed
	bottom: 0px
	left: 0px
	right: 0px
	height: $HEIGHT_LAYOUT_FOOTER
	z-index: -1

button
	border: none

input.InputUpperCase
	text-transform: uppercase
	&::placeholder
		text-transform: none


// SETUP - END


// CONDITIONER - START

.MarginTopMedium
	margin-top: $SPACE_MEDIUM

.Margin0
	margin: 0 !important

.MarginTop0
	margin-top: 0 !important

.MarginTopBottom0
	margin-top: 0 !important
	margin-bottom: 0 !important

.Width100
	width: 100% !important

.Width95
	width: 95% !important

.Width75
	width: 75% !important

.Margin0Padding0
	margin: 0px
	padding: 0px

.PaddingLR0
	padding-left: 0 !important
	padding-right: 0 !important

.AlignStart
	align-items: flex-start !important

.AlignSelfStart
	align-self: flex-start !important

.AlignCenter
	align-items: center !important

.AlignSelfCenter
	align-self: center !important

.PreWrap
	white-space: pre-wrap

.WidthAuto
	width: auto !important

.PaddingLeftBorder
	padding-left: 30px
	border-left: solid 3px #E6E6E6

.SemiBold
	font-family: $URL_FAMILYTERTIARY_TERTIARY

.Bold
	font-family: $URL_FAMILYTERTIARY_SECONDARY

.BorderBottomLeft35
	border-bottom-left-radius: 35px

.BorderBottomRight35
	border-bottom-right-radius: 35px

.JustifyCenter
	justify-content: center !important

.Padding0
	padding: 0px !important

.PremiumTotalContainer
	display: flex !important
	justify-content: space-between !important

.PremiumTotalLabel
	font-size: 32px !important
	color: #A5C9E9 !important

.PremiumH2Bold
	font-size: 32px !important
	font-weight: bold !important
	text-align: left

.Margin0Top0Bottom0

/* FLEX */

.FlexGrow0
	flex-grow: 0 !important
.FlexGrow1
	flex-grow: 1 !important
.FlexGrow2
	flex-grow: 2 !important
.FlexGrow3
	flex-grow: 3 !important
.FlexGrow4
	flex-grow: 4 !important
.FlexGrow5
	flex-grow: 5 !important
.FlexShrink0
	flex-shrink: 0 !important
.FlexShrink1
	flex-shrink: 1 !important
.FlexShrink2
	flex-shrink: 2 !important
.FlexShrink3
	flex-shrink: 3 !important
.FlexShrink4
	flex-shrink: 4 !important
.FlexShrink5
	flex-shrink: 5 !important

// CONDITIONER - END
